import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import { Badge } from 'react-bootstrap'

import './DeeplinkNotifyButton.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const DeeplinkNotifyButton = ({ handleClick, poolCount }) => (
    <Button className={poolCount ? "DeeplinkNotifyButton anim" : "DeeplinkNotifyButton"} onClick={handleClick}>
      <i className="fas fa-plus-circle"></i>
      <span className="btn-text">House Pool</span>
      {poolCount ? <Badge pill variant="danger">{poolCount}</Badge> : null}
    </Button>
)

DeeplinkNotifyButton.defaultProps = {
};

DeeplinkNotifyButton.propTypes = {
  handleClick: PropTypes.func,
};

export default DeeplinkNotifyButton;

