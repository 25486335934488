import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import UAParser from 'ua-parser-js'

import './DownloadButton.css';
import downloadButtonImage from './download-button.jpg'

class DownloadButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  }

  getOS() {
    const parser = new UAParser()
    const result = parser.getResult();
    return result.os.name
  }

  getUrl() {
    const os = this.getOS()
    return (os === 'iOS' || os === 'Mac OS') ?
      'https://itunes.apple.com/us/app/10x10-sport-squares/id938010802?mt=8' :
      'https://play.google.com/store/apps/details?id=com.tenxten&hl=en'
  }

  handleClick = () => {
    const url = this.getUrl()
    window.open(url)
  }

  render() {
    const { className } = this.props
    const url = this.getUrl()
    return (
      <img src={downloadButtonImage} alt={url} onClick={this.handleClick} className={classNames('DownloadButton-download', className)} />
    )
  }
}

export default DownloadButton;
