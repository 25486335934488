import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './SubTitle.css';

class SubTitle extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
  }

  render() {
    const { className, children } = this.props
    return (
      <h3 className={classNames("SubTitle", className)}>{children}</h3>
    );
  }
}

export default SubTitle;

