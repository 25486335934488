import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import './CopyrightNavbar.css'

class CopyrightNavbar extends PureComponent {
  render() {
    return (
      <nav className="CopyrightNavbar-container">
        <ul className="CopyrightNavbar-menu">
          <li><Link to="/home">HOME</Link></li>
          <li><Link to="/host">HOSTS</Link></li>
          <li><Link to="/player">PLAYERS</Link></li>
          <li><Link to="/about">ABOUT US</Link></li>
          <li><Link to="/faq">FAQS</Link></li>
        </ul>
      </nav>
    );
  }
}

export default CopyrightNavbar;
