import React, { PureComponent } from 'react';
import classes from './NotFound.css';

class NotFound extends PureComponent {
  render() {
    return (
      <div className={classes.container}>
        <h1>Doh! 404!</h1>
        <p>These are <em>not</em> the droids you are looking for!</p>
      </div>
    );
  }
}

export default NotFound;

