import React, { PureComponent } from 'react';

import Title from '../../components/Title/Title';
import Content from '../../components/Content/Content';
import Description from '../../components/Description/Description';

import withLayout from '../../utilities/withLayout';

import './About.css';

class About extends PureComponent {
  render() {
    const { screen } = this.props
    const { isDesktop } = screen
    return (
      <div className="Host-container">
        <Description className="About-description">
          <Title>ABOUT US</Title>
          {
            isDesktop ? (
              <Content>
              We are sports enthusiasts who love to watch the game and
              <br />
              play office pools. Football pools are usually played during the
              <br />
              Super Bowl. We wanted to bring the fun all-season long and
              <br />
              across different sports. 10x10 was created with the idea of
              <br />
              turning the traditional pen & paper pool into a digital and
              <br />
              easily accessible tool. Our app takes the work out of running a
              <br />
              pool but is still a great way to interact with friends over sports.
              <br />
              Whether it’s football or basketball, you can now play in pools
              <br />
              all-year-round to make your favorite sport even more
              <br />
              fun.
              </Content>
            ) : (
              <Content>
              We are sports enthusiasts who love to watch the game and play
              <br />
              office pools. Football pools are usually played during the Super
              <br />
              Bowl. We wanted to bring the fun all-season long and across
              <br />
              different sports. 10x10 was created with the idea of turning the
              <br />
              traditional pen & paper pool into a digital and easily accessible tool.
              <br />
              Our app takes the work out of running a pool but is still a great way
              <br />
              to interact with friends over sports. Whether it’s football or
              <br />
              basketball, you can now play in pools all-year-round to make your
              <br />
              favorite sport even more fun
              </Content>
            )
          }
        </Description>
      </div>
    );
  }
}

export default withLayout(About);
