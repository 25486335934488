import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Title.css';

class Title extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array,
    ]),
  }

  render() {
    const { className, children } = this.props
    return (
      <h1 className={classNames("Title", className)}>{children}</h1>
    );
  }
}

export default Title;

