import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import mailIconImg from './mail-icon.png'
import phoneIconImg from './phone-icon.png'

import './ContactBox.css';

class ContactBox extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    return (
      <div className="ContactBox-container">
        <div className="ContactBox-box">
          <img src={mailIconImg} alt="mail" />
          <h4>Email: <a href="mailto:support@10x10xu.com">SUPPORT@10X10XU.COM</a></h4>
        </div>
        <div className="ContactBox-box">
          <img src={phoneIconImg} alt="mail" />
          <h4>Call: <a href="tel:5166558515">(516)655-8515</a></h4>
        </div>
      </div>
    )
  }
}

export default ContactBox;
