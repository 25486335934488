import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import SubTitle from '../SubTitle/SubTitle'
import Content from '../Content/Content'
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks'
import ContactBox from '../ContactBox/ContactBox'
import LinkButton from '../LinkButton/LinkButton'
import CopyrightNavbar from '../CopyrightNavbar/CopyrightNavbar'
import LogoImg from './Logo.png'

import './Footer.css'

class Footer extends PureComponent {
  static propTypes = {
    screen: PropTypes.object,
  }

  render() {
    const { isDesktop } = this.props.screen
    const content = isDesktop ? (
      <div className="Footer-container">
        <div className="Footer-box">
          <SubTitle className="Footer-logo"><img src={LogoImg} alt="10x10" /></SubTitle>
          <Content className="Footer-content">
            10x10's leading algorithmic technology generates pool numbers at random. No more photocopied papers. Access your pool real-time from you mobile phone.
          </Content>
          <div className="Footer-download-button-box">
            <LinkButton href="https://play.google.com/store/apps/details?id=com.tenxten" type="google" />
            <LinkButton href="https://itunes.apple.com/us/app/10x10-sport-squares/id938010802?mt=8" type="apple" />
          </div>
        </div>
        <div className="Footer-box">
          <SubTitle className="Footer-subtitle">CONTACT INFO</SubTitle>
          <Content className="Footer-content">
            Whether it's a suggestion to improve the user experience, general questions, or technical support, we'd love to hear from you! Send us an email and we'll try to respond as soon as possible.
          </Content>
          <ContactBox />
        </div>
        <div className="Footer-box">
          <SubTitle className="Footer-subtitle">FOLLOW US</SubTitle>
          <Content className="Footer-content">
            Keep up with 10x10's latest news and development, and share your favorite moments using 10x10 with us.
          </Content>
          <SocialMediaLinks />
        </div>
      </div>
    ) : (
      <div className="Footer-container">
        <div className="Footer-box">
          <span className="Footer-followus-text">FOLLOW US </span>
          <SocialMediaLinks />
        </div>
        <div className="Footer-box">
          <CopyrightNavbar />
        </div>
        <div className="Footer-box">
          <ContactBox />
        </div>
      </div>
    )
    return content
  }
}

export default Footer;
