import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import './Button.css';

class Button extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func
  }

  render() {
    const { children, className, ...props } = this.props
    return (
      <button {...props} className={classNames('Button', className)}>
        {children}
      </button>
    )
  }
}

export default Button;
