import React, { PureComponent } from 'react';

import Banner from '../../components/Banner/Banner';
import DownloadButton from '../../components/DownloadButton/DownloadButton'
import LinkButton from '../../components/LinkButton/LinkButton';
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title';
import SubTitle from '../../components/SubTitle/SubTitle';
import Content from '../../components/Content/Content';
import Description from '../../components/Description/Description';
import Media from '../../components/Media/Media';

import withLayout from '../../utilities/withLayout';

import Banner1DesktopImg from './banner-1-desktop.jpg'
import Banner2DesktopImg from './banner-2-desktop.jpg'
import Banner1MobileImg from './banner-1-mobile.jpg'
import Banner2MobileImg from './banner-2-mobile.jpg'

import InstallDesktopImg from './install-desktop.png'
import InstallMobileImg from './install-mobile.png'
import PlayDesktopImg from './play-desktop.png'
import PlayMobileImg from './play-mobile.png'
import ShareDesktopImg from './share-desktop.png'
import ShareMobileImg from './share-mobile.png'

import './Home.css'

class Home extends PureComponent {
  render() {
    const { screen, handleVideoShow } = this.props
    const { isDesktop, scrollTop } = screen
    const Video = <Media scrollTop={scrollTop} screen={screen} handleVideoShow={handleVideoShow} />
    return (
      <div className="Home-container">
        <Banner
          imgSource={isDesktop ? Banner1DesktopImg : Banner1MobileImg} screen={screen}
          contentClassName="Home-Banner-Content-1"
          subChild={Video}
        >
          {
            isDesktop
              ? (
                <div>
                  <Title>10X10 - TEN BY TEN</Title>
                  <SubTitle>Digital Sports Pools - Fair and Square</SubTitle>
                </div>
              )
              : (
                <div>
                  <Title>10X10<br />Ten By Ten</Title>
                  <SubTitle>Digital Sports Pools<br />Fair and Square</SubTitle>
                </div>
              )
          }
          {
            isDesktop ? (
              <Content>
                10X10’s leading algorithmic technology generates pool numbers at random.
                <br />
                No more photocopied papers. Access your pool real-time from your mobile phone.
              </Content>
            ) : (
              <Content>
                10X10’s leading algorithmic technology
                <br />
                generates pool numbers at random.
                <br />
                No more photocopied papers. Access
                <br />
                your pool real-time from your phone.
              </Content>
            )
          }
          <div className="Home-button-box">
            <LinkButton href="https://play.google.com/store/apps/details?id=com.tenxten&hl=en" type="google" />
            <LinkButton href="https://itunes.apple.com/us/app/10x10-sport-squares/id938010802?mt=8" type="apple" />
          </div>
        </Banner>
        <Description className="getstart-description">
          <Button className="getstart-button" onClick={() => handleVideoShow('https://youtu.be/P_ui9KYo-Qo')}>HOW TO START</Button>
          <Title className="getstart-title">GET STARTED</Title>
          <div className="getstart-container">
            <div className="getstart-box">
              <div className="getstart-img-box">
                <img src={isDesktop ? InstallDesktopImg : InstallMobileImg} alt="install" />
              </div>
              <div className="getstart-title-box">
                <SubTitle className="getstart-subtitle">INSTALL</SubTitle>
                <Content className="getstart-content">
                  Download the 10x10 mobile
                  <br />
                  app and create your login
                </Content>
              </div>
            </div>
            <div className="getstart-box">
              <div className="getstart-img-box">
                <img src={isDesktop ? PlayDesktopImg : PlayMobileImg} alt="play" />
              </div>
              <div className="getstart-title-box">
                <SubTitle className="getstart-subtitle">PLAY</SubTitle>
                <Content className="getstart-content">
                  Choose your role as a
                  <br />
                  HOST or as a PLAYER
                </Content>
              </div>
            </div>
            <div className="getstart-box">
              <div className="getstart-img-box">
                <img src={isDesktop ? ShareDesktopImg : ShareMobileImg} alt="share" />
              </div>
              <div className="getstart-title-box">
                <SubTitle className="getstart-subtitle">SHARE</SubTitle>
                <Content className="getstart-content">
                  Spread the word and recruit
                  <br />
                  friends to play by sharing the
                  <br />
                  pool’s unique code
                </Content>
              </div>
            </div>
          </div>
          {/* <LinkButton href="/player" className="getstart-button">HOW TO PLAY</LinkButton> */}

        </Description>
        <Banner
          imgSource={isDesktop ? Banner2DesktopImg : Banner2MobileImg} screen={screen}
          contentClassName="Home-Banner-Content-2"
        >
          {
            isDesktop ? <Title>MVP SPOTLIGHT</Title> : (
              <div>
                <Title>MVP</Title>
                <Title>SPOTLIGHT</Title>
              </div>
            )
          }
          {
            isDesktop ? (
              <Content>
                With the 10X10 mobile app, you can invite colleagues
                <br />
                and friends with autogenerated text,
                <br />
                pick your boxes right from your phone,
                <br />
                and view your pool in real-time.
              </Content>
            ) : (
              <Content>
                With the 10X10 mobile app, you can invite colleagues
                <br />
                and friends with autogenerated text,
                <br />
                pick your boxes right from your phone,
                <br />
                and view your pool in real-time.
              </Content>
            )
          }
          <DownloadButton className="Home-download-button" />
        </Banner>
      </div>
    );
  }
}

export default withLayout(Home);
