import React, { PureComponent } from 'react';

import Banner from '../../components/Banner/Banner';
import Title from '../../components/Title/Title';
import SubTitle from '../../components/SubTitle/SubTitle'
import Content from '../../components/Content/Content';
import Description from '../../components/Description/Description';
import LinkButton from '../../components/LinkButton/LinkButton';

import withLayout from '../../utilities/withLayout';

import Banner1DesktopImg from './banner-1-desktop.jpg'
import Banner1MobileImg from './banner-1-mobile.jpg'
import './FAQ.css';

class FAQ extends PureComponent {
  render() {
    const { screen } = this.props
    const { isDesktop } = screen
    return (
      <div className="FAQ-container">
        <Banner imgSource={isDesktop ? Banner1DesktopImg : Banner1MobileImg} screen={screen} />
        <Description className="FAQ-description">
          <Title>FAQ</Title>
          <SubTitle className="FAQ-SubTitle">WHAT MAKES 10X10 SPECIAL?</SubTitle>
          <Content className="FAQ-Content">
            10x10 promotes efficiency, scalability, and fairness. Players choose boxes from their phone and can view the pool real-time from the mobile app. 10x10’s leading technology generates each pool’s numbers at random, so everyone can just focus on the best part…game time
          </Content>
          <SubTitle className="FAQ-SubTitle">WHERE CAN I DOWNLOAD 10X10?</SubTitle>
          <div className="FAQ-button-box">
            <LinkButton href="https://play.google.com/store/apps/details?id=com.tenxten&hl=en" type="google" />
            <LinkButton href="https://itunes.apple.com/us/app/10x10-sport-squares/id938010802?mt=8" type="apple" />
          </div>
          <Content className="FAQ-Content">
            You can also search “10X10 sports square” in the Apple store or the Play store.
          </Content>
          <SubTitle className="FAQ-SubTitle">HOW MUCH DOES IT COST TO HOST A POOL?</SubTitle>
          <Content className="FAQ-Content">
            Hosting works on a points system — 100 points cost $.99, 300 points cost $1.99, 1000 points cost $5.99, and 2000 points cost $9.99.
          </Content>
          <Content className="FAQ-Content">
            For NFL, it requires 100 points to host a pre-season pool, 300 for regular season pool, and 1000 for a playoff pool and 2000 for a superbowl pool. For NBA, it requires 200 points for preseason game, 500 for regular season game, 1000 for playoff game and 2000 for championships.
          </Content>
          <SubTitle className="FAQ-SubTitle">HOW MUCH DOES IT COST TO PLAY IN A POOL?</SubTitle>
          <Content className="FAQ-Content">
            It is free to download the 10x10 app. Please contact the pool’s host directly to discuss participation rules and prizes.
          </Content>
        </Description>
      </div>
    );
  }
}

export default withLayout(FAQ);
