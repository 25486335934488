import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'

import './Mask.css'

function Mask({ children, className, show, onClick }) {
  const classMapping = {
    show: show,
    mask: true
  }
  return (
    <div
      className={classNames(className, classMapping)}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Mask.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  className: PropTypes.string,
  show: PropTypes.bool,
  onClick: PropTypes.func
}

export default Mask
