import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import PoolView from '../PoolView'
import './PoolSwipe.css'

const settings = {
  dots: true,
  infinite: false, // depend on deeplinks length
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  response: [
    { breakpoint: 1150, settings: { arrows: false } }
  ]
}

const PoolSwipe = ({ deeplinks, screen }) => (
  <Slider {...settings}>
    {
      deeplinks.map((deeplink, index) => (
        <div key={`HOUSE-pool-${index}`}><PoolView deeplink={deeplink} screen={screen} /></div>
      ))
    }
  </Slider>
)

PoolSwipe.defaultProps = {
};

PoolSwipe.propTypes = {
  deeplinks: PropTypes.array,
  screen: PropTypes.object,
};

export default PoolSwipe;

