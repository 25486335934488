import React, { PureComponent } from 'react';

import Banner from '../../components/Banner/Banner';
import Title from '../../components/Title/Title';
import Content from '../../components/Content/Content';
import DownloadButton from '../../components/DownloadButton/DownloadButton'
import HowTo from '../../components/HowTo/HowTo'

import withLayout from '../../utilities/withLayout';

import Banner1DesktopImg from './banner-1-desktop.jpg'
import Banner1MobileImg from './banner-1-mobile.jpg'
import './Player.css';

class Player extends PureComponent {
  render() {
    const { screen, handleVideoShow } = this.props
    const { isDesktop } = screen
    return (
      <div className="Player-container">
        <Banner
          imgSource={isDesktop ? Banner1DesktopImg : Banner1MobileImg}
          screen={screen}
          contentClassName="Player-Banner-Content-1"
        >
          <Title>FAIR AND SQUARE</Title>
          {
            isDesktop ? (
              <Content>
                10x10 takes the guess work out of pools by automatically generating the pool numbers at random.
              </Content>
            ) : (
                <Content>
                  10x10 takes the guess work out of pools by automatically
                <br />
                  generating the pool numbers at random.
              </Content>
              )
          }
          <DownloadButton className="Player-download-button" />
        </Banner>
        <HowTo type="player" screen={screen} onLinkClick={handleVideoShow} />
      </div>
    );
  }
}

export default withLayout(Player);
