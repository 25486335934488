import React from 'react'
import PropTypes from 'prop-types';

import loadingImage from './Loading.svg'

import './Loading.css'

function Loading({ src = '', alt = 'loading' }) {
  return (
    <img src={src || loadingImage} alt={alt} />
  )
}

Loading.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
}

export default Loading
