import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SubTitle from '../SubTitle/SubTitle';
import Content from '../Content/Content';

import arrowImg from './arrow.png'

import './HowToBox.css';

class HowToBox extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    noArrow: PropTypes.bool,
  }

  render() {
    const { image, title, content, noArrow } = this.props
    return (
      <div className="HowToBox-box">
        <div className="HowToBox-img-box">
          <img className="HowToBox-img" src={image} alt={title} />
          {noArrow ? '' : <img className="HowToBox-img-arrow" src={arrowImg} alt="arrow" /> }
        </div>
        <div className={noArrow ? "HowToBox-title-box-noArrow" : "HowToBox-title-box"}>
          <SubTitle className="HowToBox-subtitle">{title}</SubTitle>
          <Content className="HowToBox-content">
            <span dangerouslySetInnerHTML={{__html: content}} />
          </Content>
        </div>
      </div>
    )
  }
}

export default HowToBox;






