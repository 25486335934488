import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import QRCode from 'qrcode.react'

import './DeeplinkSuperbowlModal.css'

const DeeplinkSuperbowlModal = ({ isOpen, handleClose, deeplinks, screen }) => (
  <Modal
    open={isOpen}
    onClose={handleClose}
    little
    classNames={{ modal: 'deeplink-modal' }}
  >
    <h2 className="deeplink-modal-body">Create Superbowl Pool</h2>
    <div className="deeplink-modal-body">
      <QRCode value={'https://tenxten.app.link?mode=host&action=create-pool&game-type=nfl&game-season-type=championship'} size={screen.isDesktop ? 256 : 128} />
    </div>
  </Modal>
)

DeeplinkSuperbowlModal.defaultProps = {
};

DeeplinkSuperbowlModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  deeplinks: PropTypes.array,
  screen: PropTypes.object,
};

export default DeeplinkSuperbowlModal;

