import React, { PureComponent } from 'react'

import CopyrightNavbar from '../CopyrightNavbar/CopyrightNavbar'

import './Copyright.css'

class Copyright extends PureComponent {
  render() {
    return (
      <div className="Copyright-container">
        <div className="Copyright-box">
          <CopyrightNavbar />
        </div>
        <div className="Copyright-box">
          10x10 does not support any form of betting and money exchange between players and hosts
        </div>
        <div className="Copyright-box">
          © Copyright 10x10 All Right Reserved
        </div>
      </div>
    );
  }
}

export default Copyright;
