import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import UAParser from 'ua-parser-js'

import video from './10x10.mp4'
import landscapeVideo from './10x10-landscape.mp4'
import posterImage from './poster.jpg'
import posteriOSImage from './poster-ios.jpg'

import './Media.css';

class Media extends PureComponent {
  static propTypes = {
    screen: PropTypes.object,
    scrollTop: PropTypes.number,
    className: PropTypes.string,
    handleVideoShow: PropTypes.func
  }

  componentDidMount() {
    const parser = new UAParser()
    const result = parser.getResult()
    const osName = result.os.name
    const { isMobile } = this.props.screen
    const isiOS = (osName === 'iOS' || osName === 'Mac OS')
    const video = document.getElementById('play-demo-video')
    video.addEventListener('ended', (e) => {
      video.load()
    })
    this.isMobileAndiOS = isiOS && isMobile
    this.controlProps = {} // this.isMobileAndiOS ? { controls: true } : {}
  }

  handleClick = (e) => {
    const video = e.target
    video.playbackRate = 0.75
    if (video.paused) {
      video.play()
    } else {
      video.pause()
    }
  }

  render() {
    const { handleVideoShow } = this.props
    return (
      <div className="Media-container">
        <video
          id="play-demo-video"
          width="100%" height="100%"
          poster={this.isMobileAndiOS ? posteriOSImage : posterImage}
          muted
          onClick={() => handleVideoShow(landscapeVideo, '')}
          {...this.controlProps}
        />
      </div>
    )
  }
}

export default Media;
