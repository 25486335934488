import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Title from '../Title/Title';
import HowToBox from '../HowToBox/HowToBox';
import Description from '../Description/Description';
import Button from '../Button/Button';

import ChooseDesktopImg from './choose-desktop.png'
import ChooseMobileImg from './choose-mobile.png'
import HostDesktopImg from './host-desktop.png'
import HostMobileImg from './host-mobile.png'
import InviteDesktopImg from './invite-desktop.png'
import InviteMobileImg from './invite-mobile.png'
import NewpoolDesktopImg from './newpool-desktop.png'
import NewpoolMobileImg from './newpool-mobile.png'
import PlayerDesktopImg from './player-desktop.png'
import PlayerMobileImg from './player-mobile.png'
import RandomizationDesktopImg from './randomization-desktop.png'
import RandomizationMobileImg from './randomization-mobile.png'
import WinnerDesktopImg from './winner-desktop.png'
import WinnerMobileImg from './winner-mobile.png'
import InstallDesktopImg from './install-desktop.png'
import InstallMobileImg from './install-mobile.png'

import './HowTo.css';

class HowTo extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array,
    ]),
    type: PropTypes.string.isRequired,
    screen: PropTypes.object,
    onLinkClick: PropTypes.func
  }

  get howToHost() {
    const { isDesktop } = this.props.screen
    const { onLinkClick } = this.props
    return (
      <Description>
        <Button className="HowTo-button" onClick={() => onLinkClick('https://youtu.be/Av-f2qFYIqU')}>HOW TO HOST</Button>
        <Title className="HowTo-title">HOW TO HOST A POOL</Title>
        <div className="HowTo-container">
          <HowToBox
            title="INSTALL"
            content={
              isDesktop ?
                "Download the 10x10 mobile app<br />and create your login" :
                "Download the 10x10 mobile<br />app and create your login"
            }
            image={isDesktop ? InstallDesktopImg : InstallMobileImg}
          />
          <HowToBox
            title="HOST"
            content={
              isDesktop ?
                "Ensure you have enough credits to host a game<br />or purchase new credits via the app" :
                "Ensure you have enough credits<br />to host a game or purchase new<br />credits via the app"
            }
            image={isDesktop ? HostDesktopImg : HostMobileImg}
          />
          <HowToBox
            title="NEW POOL"
            content={
              isDesktop ?
                "Fill in your pool information such as<br />the maximum number of bets and prizes" :
                "Fill in your pool information<br />such as the maximum number<br />of bets and prizes"
            }
            image={isDesktop ? NewpoolDesktopImg : NewpoolMobileImg}
          />
          <HowToBox
            title="SHARE"
            content={
              isDesktop ?
                "Spread the word and recruit friends to join<br />your pool by sharing the pool’s unique code" :
                "Spread the word and recruit<br />friends to join your pool by<br />sharing the pool’s unique code"
            }
            image={isDesktop ? InviteDesktopImg : InviteMobileImg}
          />
          <HowToBox
            title="RANDOMIZATION"
            content={
              isDesktop ?
                "After all players have chosen their squares, 10X10’s<br />leading algorithmic technology will generate<br />numbers to each column and row at random" :
                "After all players have chosen their<br />squares, 10X10’s leading algorithmic<br />technology will generate numbers to<br />each column and row at random"
            }
            image={isDesktop ? RandomizationDesktopImg : RandomizationMobileImg}
          />
          <HowToBox
            title="GAME TIME"
            content={
              isDesktop ?
                "Access your pools real time. The winner is the box<br />that contains the last digit of each team’s score. The winner will<br />automatically receive an email notification of their<br />winning box." :
                "Access your pools real time. The<br />winner is the box that contains the<br />last digit of each team’s score. The winner<br />will automatically receive an email<br />notification of their<br />winning box."
            }
            image={isDesktop ? WinnerDesktopImg : WinnerMobileImg}
            noArrow={true}
          />
        </div>
      </Description>
    )
  }

  get howToPlayer() {
    const { isDesktop } = this.props.screen
    const { onLinkClick } = this.props
    return (
      <Description>
        <Button className="HowTo-button" onClick={() => onLinkClick('https://youtu.be/kImTwXVGcnI')}>HOW TO PLAY</Button>
        <Title className="HowTo-title">HOW TO PLAY</Title>
        <div className="HowTo-container">
          <HowToBox
            title="INSTALL"
            content={
              isDesktop ?
                "Download the 10x10 mobile app<br />and create your login" :
                "Download the 10x10 mobile<br />app and create your login"
            }
            image={isDesktop ? InstallDesktopImg : InstallMobileImg}
          />
          <HowToBox
            title="PLAY"
            content='Enter the unique pool code from<br />your host and click "Join Now"'
            image={isDesktop ? PlayerDesktopImg : PlayerMobileImg}
          />
          <HowToBox
            title="CHOOSE YOUR BOX"
            content={
              isDesktop ?
                "Choose any open box in the pool<br />and confirm your pick" :
                "Choose any open box in the<br />pool and confirm your pick"
            }
            image={isDesktop ? ChooseDesktopImg : ChooseMobileImg}
          />
          <HowToBox
            title="RANDOMIZATION"
            content={
              isDesktop ?
                "After all players have chosen their square, 10X10’s<br />leading algorithmic technology will generate<br />numbers to each column and row at random" :
                "After all players have chosen their<br />square, 10X10’s leading algorithmic<br />technology will generate numbers to<br />each column and row at random"
            }
            image={isDesktop ? RandomizationDesktopImg : RandomizationMobileImg}
          />
          <HowToBox
            title="GAME TIME"
            content={
              isDesktop ?
                "Access your pools real time. The winner is the box<br />that contains the last digit of each team’s score. S/he<br />will automatically receive an email notification of<br />their winning box." :
                "Access your pools real time. The<br />winner is the box that contains the<br />last digit of each team’s score. S/he<br />will automatically receive an email<br />notification of their winning box."
            }
            image={isDesktop ? WinnerDesktopImg : WinnerMobileImg}
            noArrow={true}
          />
        </div>
      </Description>
    )
  }

  render() {
    const { type } = this.props
    return type === 'host' ? this.howToHost : this.howToPlayer
  }
}

export default HowTo;

