import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'

import './DeeplinkGameButton.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const DeeplinkGameButton = ({ handleClick, screen }) => {
  const { isDesktop } = screen
  return (
    <Button 
      className="DeeplinkGameButton" 
      onClick={isDesktop ? handleClick : () => { window.open("https://tenxten.app.link?mode=host&action=create-pool&game-type=nfl&game-season-type=championship", "_blank") }}
    >
      <i className="fas fa-plus-circle"></i>
      <span className="btn-text">Superbowl LVII</span>
    </Button>
)}

DeeplinkGameButton.defaultProps = {
};

DeeplinkGameButton.propTypes = {
  handleClick: PropTypes.func,
};

export default DeeplinkGameButton;

