import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  Home,
  About,
  Host,
  Player,
  FAQ,
  NotFound,
} from '../pages'

export default (
  <Switch>
    {/* Routes */}
    <Route exact path="/" component={Home} />
    <Route path="/home" component={Home} />
    <Route path="/about" component={About} />
    <Route path="/host" component={Host} />
    <Route path="/player" component={Player} />
    <Route path="/faq" component={FAQ} />
    {/* Catch all route */}
    <Route component={NotFound} status={404} />
  </Switch>
)
