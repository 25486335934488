import React from 'react'
import PropTypes from 'prop-types'

import dateFormat from 'dateformat'
import classNames from 'classnames'

import vsImage from './vs.png'

const imgHostUrl = 'https://prod.10x10xu.com/api'

const PoolGameView = ({ deeplink, handleViewChange }) => {
  const gameDatetime = new Date(deeplink.game_datetime)
  const isGaming = deeplink.status === 'gaming'
  return (
    <div className="PoolGameView-container">
      <h3 className="PoolGameView-title-container">
        <button className="PoolGameView-pool-button qrcode" onClick={handleViewChange('QRCODE')}><i className="fas fa-qrcode"></i></button>
        <p className="PoolGameView-date-box">
          {dateFormat(gameDatetime, 'mediumDate')}
          <br />
          {dateFormat(gameDatetime, 'shortTime')}
        </p>
        {deeplink.title}
        <button className="PoolGameView-pool-button prize flash" onClick={handleViewChange('PRIZE')}><i className="fas fa-trophy"></i></button>
      </h3>
      <div className="PoolGameView-game-box">
        <div className="PoolGameView-team-box">
          <h4>{deeplink.away.name}</h4>
          <img src={`${imgHostUrl}/team/${deeplink.away.team}/logo`} alt={deeplink.away.name} />
        </div>
        <img className="PoolGameView-team-vs-img" src={vsImage} alt="vs" />
        <div className="PoolGameView-team-box">
          <h4>{deeplink.home.name}</h4>
          <img src={`${imgHostUrl}/team/${deeplink.home.team}/logo`} alt={deeplink.home.name} />
        </div>
      </div>
      <div className="PoolGameView-message-container">
        <p className="PoolGameView-pool-code">{deeplink.join_code}</p>
        {/* <p className={classNames("PoolGameView-pool-status", { red: isGaming, flash: isGaming })}>
          {deeplink.status.toUpperCase()}
        </p> */}
      </div>
      <div className="PoolGameView-button-box">
        <a href={deeplink.url}>JOIN NOW</a>
      </div>
    </div>
  )
}

PoolGameView.defaultProps = {
};

PoolGameView.propTypes = {
  deeplink: PropTypes.object,
  handleViewChange: PropTypes.func,
  screen: PropTypes.object,
};

export default PoolGameView;
