import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'

import PoolGameView from './PoolGameView'
import PoolPrizeView from './PoolPrizeView'
import PoolQRCodeView from './PoolQRCodeView'

import './PoolView.css'

const PoolView = ({ deeplink, view, handleViewChange, screen }) => {
  const componentMap = {
    GAME: PoolGameView,
    PRIZE: PoolPrizeView,
    QRCODE: PoolQRCodeView,
  }
  const WrappedComponent = componentMap[view] || PoolGameView
  return (
    <WrappedComponent
      deeplink={deeplink}
      handleViewChange={handleViewChange}
      screen={screen}
    />
  )
}

PoolView.defaultProps = {
};

PoolView.propTypes = {
  deeplink: PropTypes.object,
  screen: PropTypes.object,
};

export default compose(
  withState('view', 'setView', 'GAME'),
  withHandlers({
    handleViewChange: ({ setView }) => name => e => {
      setView(name)
    },
  }),
)(PoolView);

