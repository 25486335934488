import React, { PureComponent } from 'react';

import Banner from '../../components/Banner/Banner';
import Title from '../../components/Title/Title';
import Content from '../../components/Content/Content';
import DownloadButton from '../../components/DownloadButton/DownloadButton'
import HowTo from '../../components/HowTo/HowTo';

import withLayout from '../../utilities/withLayout';

import Banner1DesktopImg from './banner-1-desktop.jpg'
import Banner1MobileImg from './banner-1-mobile.jpg'
import Banner2DesktopImg from './banner-2-desktop.jpg'
import './Host.css';

class Host extends PureComponent {
  render() {
    const { screen, handleVideoShow } = this.props
    const { isDesktop } = screen
    return (
      <div className="Host-container">
        <Banner
          imgSource={isDesktop ? Banner1DesktopImg : Banner1MobileImg}
          screen={screen}
          contentClassName="Host-Banner-Content-1"
        >
          <Title>IT'S A GAME CHANGER</Title>
          {
            isDesktop ? (
              <Content>
                Forget the days of drawing numbers out of a hat, standing by the copying machine, and
                <br />
                handing out photocopies. Keep up with the game and your pools all in one place using the 10x10 app.
              </Content>
            ) : (
              <Content>
                Forget the days of drawing numbers out of a hat, standing by the copying
                <br />
                machine, and handing out photocopies. Keep up with the game and your pools all
                <br />
                in one place using the 10X10 app.
              </Content>
            )
          }
          <DownloadButton className="Host-download-button" />
        </Banner>
        <HowTo type="host" screen={screen} onLinkClick={handleVideoShow} />
        {
          isDesktop ? (
            <Banner
              imgSource={Banner2DesktopImg}
              screen={screen}
              contentClassName="Host-Banner-Content-2"
            >
              <Title>POOL SUBJECT IDEA</Title>
              <Content>
                10x10 caters to both NFL and NBA —
                <br />
                for American football and basketball alike,
                <br />
                try using the last digit of each teams's score
                <br />
                as an indication of the winner's box
              </Content>
            </Banner>
          ) : ''
        }
      </div>
    );
  }
}

export default withLayout(Host);
