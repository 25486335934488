import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './NotificationBar.css';

const NotificationBar = ({ fixed, message }) => {
  const classMapping = {
    fixed,
    "NotificationBar-container": true,
  }
  return (
    <div
      className={classNames(classMapping)}
      onTouchStart={() => null}
      onTouchEnd={() => null}
    >
      <header className="marquee">{message}</header>
    </div>
  )
}

NotificationBar.propTypes = {
  fixed: PropTypes.bool,
  message: PropTypes.string.isRequired,
}

export default NotificationBar;
