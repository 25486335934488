import { SERVER_IP, SERVER_PORT } from '../config';
import $ from 'jquery'

const headers = new Headers({
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method",
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
  "Accept": "application/json",
  "Content-Type": "application/json"
});

const APIURL = `https://${SERVER_IP}/api`;

export default {
  getAPIUrl: (resource, type = '') => {
    return Promise.resolve(type)
      .then((type) => {
        let url = `${APIURL}${resource}`;
        return url;
      });
  },
  get: (resource, type) => {
    let url = `${APIURL}${resource}`;
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        cache: false,
        ifModified: true
      }).done(json => {
        resolve(json);
      });
    });
  },
  post: (resource, data, isFormData) => {
    let url = `${APIURL}${resource}`;
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        dataType: 'json',
        cache: false,
        ifModified: true
      }).done(json => {
        resolve(json);
      });
    });
  },
  put: (resource, data, isFormData) => {
    let url = `${APIURL}${resource}`;
    if (isFormData) {
      return fetch(url, {
        method: 'put',
        headers: new Headers({
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
          "AUTH": sessionStorage.getItem('AUTH')
        }),
        body: data
      });
    }
    headers.set('AUTH', sessionStorage.getItem('AUTH'));
    return fetch(url, {
      method: 'put',
      headers: headers,
      body: (data ? JSON.stringify(data) : {})
    });
  },
  delete: (resource, data) => {
    let url = `${APIURL}${resource}`;
    headers.set('AUTH', sessionStorage.getItem('AUTH'));
    return fetch(url, {
      method: 'delete',
      headers: headers,
      body: (data ? JSON.stringify(data) : {})
    });
  },
}
