import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import DownloadButton from '../DownloadButton/DownloadButton'
import LogoDesktopImg from './Logo-desktop.png'
import Button from '../Button/Button';
import './Navbar.css'

class Navbar extends PureComponent {
  static propTypes = {
    screen: PropTypes.object,
  }

  render() {
    const { isDesktop } = this.props.screen
    return (
      <nav className="Navbar-container">
        <Link style={{ display: 'flex' }} to="/home"><img className="Navbar-logo" src={LogoDesktopImg} alt="10x10" /></Link>
        {
          isDesktop ? (
            <ul className="Navbar-menu">
              <li><Link to="/host">HOSTS</Link></li>
              <li><Link to="/player">PLAYERS</Link></li>
              <li><Link to="/about">ABOUT US</Link></li>
              <li><Link to="/faq">FAQS</Link></li>
              <li>
                <div style={{ display: "flex" }}>
                  <Button className="play-button" onClick={() => window.open("https://player.prod.10x10xu.com")}>PLAY NOW</Button>
                  <DownloadButton className="Navbar-button" />
                </div>
              </li>
            </ul>
          ) : (
            <ul className="Navbar-menu">
              <li><Link to="/host">HOSTS</Link></li>
              <li><Link to="/player">PLAYERS</Link></li>
              <div style={{ display: "flex" }}>
                  <Button className="play-button" onClick={() => window.open("https://player.prod.10x10xu.com")}>PLAY NOW</Button>
                  <DownloadButton className="Navbar-button" />
                </div>
            </ul>
          )
        }
      </nav>
    );
  }
}

export default Navbar;

