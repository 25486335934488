import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import './LinkButton.css';

class LinkButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    type: PropTypes.string,
  }

  render() {
    const { children, className, type, ...props } = this.props
    const classes = {
      'LinkButton': true,
      'apple-store': type === 'apple',
      'google-play': type === 'google',
      'facebook': type === 'facebook',
      'instagram': type === 'instagram',
      'linkedin': type === 'linkedin',
      'twitter': type === 'twitter',
      [className]: true,
    }
    return (
      <a {...props} className={classNames(classes)}>
        {children}
      </a>
    )
  }
}

export default LinkButton;
