import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'

import PoolSwipe from '../PoolSwipe'

import './DeeplinkPoolModal.css'

const DeeplinkPoolModal = ({ isOpen, handleClose, deeplinks, screen }) => (
  <Modal
    open={isOpen}
    onClose={handleClose}
    little
    classNames={{ modal: 'deeplink-modal' }}
  >
    <h2 className="deeplink-modal-body">HOUSE POOLS</h2>
    <div className="deeplink-modal-body">
      {
        deeplinks.length ? <PoolSwipe deeplinks={deeplinks} screen={screen} /> : <div>No found pools</div>
      }
    </div>
  </Modal>
)

DeeplinkPoolModal.defaultProps = {
};

DeeplinkPoolModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  deeplinks: PropTypes.array,
  screen: PropTypes.object,
};

export default DeeplinkPoolModal;

