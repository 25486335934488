import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker';
import routes from './routes'

import './styles/core.css'

ReactDOM.render((
  <Router>
    {routes}
  </Router>
),
document.getElementById('root'));
registerServiceWorker();
