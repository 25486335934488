import React from 'react'
import PropTypes from 'prop-types'

import QRCode from 'qrcode.react'

const PoolQRCodeView = ({ deeplink, handleViewChange, screen }) => {
  const { join_code } = deeplink
  const { isDesktop } = screen
  return (
    <div className="PoolQRCodeView-container">
      <div className="PoolQRCodeView-qrcode-box">
        <h4 className="PoolQRCodeView-qrcode-box-title">QRCODE</h4>
        <div className="PoolQRCodeView-qrcode-box-content">
          <QRCode value={join_code} size={isDesktop ? 256 : 128} />
        </div>
      </div>
      <button className="PoolQRCodeView-back-button" onClick={handleViewChange('GAME')}>
        <i className="fas fa-arrow-left"></i>
      </button>
    </div>
  )
}

PoolQRCodeView.defaultProps = {
};

PoolQRCodeView.propTypes = {
  deeplink: PropTypes.object,
  handleViewChange: PropTypes.func,
  screen: PropTypes.object,
};

export default PoolQRCodeView;
