import React from 'react'
import compose from 'recompose/compose'
import lifecycle from 'recompose/lifecycle'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'

import ajax from './ajax'

import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Copyright from '../components/Copyright/Copyright'
import NotificationBar from '../components/NotificationBar/NotificationBar'
import Mask from '../components/Mask/Mask'
import Loading from '../components/Loading/Loading'
import DeeplinkNotifyButton from '../components/DeeplinkNotifyButton/DeeplinkNotifyButton'
import DeeplinkGameButton from '../components/DeeplinkGameButton/DeeplinkGameButton'
import DeeplinkPoolModal from '../components/DeeplinkPoolModal'
import DeeplinkSuperbowlModal from '../components/DeeplinkSuperbowlModal'
import VideoPlayer from '../components/VideoPlayer'
import Modal from 'react-responsive-modal'
import '../styles/layout.css'

const getScrollTop = () => {
  const body = document.body
  return body.scrollTop
}

const isScrollTopFixed = () => {
  return !((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 45)
}

const getScreenSize = () => {
  const body = document.body
  let size = 'desktop'

  if (body.scrollWidth <= 1150) {
    size = 'mobile'
  }

  return size
}

const withLayout = WrappedComponent => ({
  screenSize,
  scrollTopFixed,
  message,
  scrollTop,
  show,
  deeplinks,
  showPool,
  showSuperbowl,
  showVideo,
  videoUrl,
  videoType,
  handlePoolShow,
  handlePoolClose,
  handleSuperbowlShow,
  handleSuperbowlClose,
  handleVideoShow,
  handleVideoClose
}) => {
  const isDesktop = screenSize === 'desktop'
  const isMobile = screenSize === 'mobile'
  const screen = {
    size: screenSize,
    isDesktop,
    isMobile,
    scrollTop,
  }

  const videoJsOptions = {
    autoplay: true,
    sources: [{
      src: videoUrl,
      type: videoType
    }],
    techOrder: ['youtube', 'html5'],
    youtube: { "ytControls": 2 }
  }
  
  return (
    <div>
      <Navbar screen={screen} />
      <div className="DeeplinkButton-container">
        <DeeplinkNotifyButton handleClick={handlePoolShow} poolCount={deeplinks.length} /><br />
        <DeeplinkGameButton handleClick={handleSuperbowlShow} screen={screen} />
      </div>
      <DeeplinkPoolModal
        isOpen={showPool}
        handleClose={handlePoolClose}
        deeplinks={deeplinks}
        screen={screen}
      />
      <DeeplinkSuperbowlModal
        isOpen={showSuperbowl}
        handleClose={handleSuperbowlClose}
        deeplinks={deeplinks}
        screen={screen}
      />
      <Mask show={show}><Loading /></Mask>
      <Modal
        open={showVideo}
        onClose={handleVideoClose}
        little
        classNames={{ modal: 'video-modal' }}>
        <span>How To</span>
        <VideoPlayer {...videoJsOptions} screen={screen} />
      </Modal>
      <WrappedComponent screen={screen} handleVideoShow={handleVideoShow} />
      <Footer screen={screen} />
      {isDesktop ? <Copyright /> : ''}
      <NotificationBar
        fixed={scrollTopFixed}
        message={message}
      />
    </div>
  )
}

let idResize;

export default compose(
  withState('screenSize', 'setScreenSize', getScreenSize()),
  withState('scrollTopFixed', 'setScrollTopFixed', true),
  withState('message', 'setMessage', ''),
  withState('deeplinks', 'setDeeplinks', []),
  withState('showPool', 'setShowPool', false),
  withState('showSuperbowl', 'setShowSuperbowl', false),
  withState('showVideo', 'setShowVideo', false),
  withState('videoUrl', 'setVideoUrl', ''),
  withState('videoType', 'setVideoType', 'video/youtube'),
  withState('scrollTop', 'setScrollTop', getScrollTop()),
  withState('show', 'setShow', false),
  withHandlers({
    handleResize: ({ setScreenSize }) => () => {
      clearTimeout(idResize);
      idResize = setTimeout(() => {
        const screenSize = getScreenSize()
        setScreenSize(screenSize)
      }, 0);
    },
    handleScroll: ({ setScrollTopFixed, setScrollTop, isMounted }) => () => {
      const scrollTopFixed = isScrollTopFixed()
      setScrollTopFixed(scrollTopFixed)
      setScrollTop(setScrollTop)
    },
    getNotifications: ({ setMessage }) => () => {
      ajax.get('/notification/publishing')
        .then((res) => {
          const notification = res.data.notifications.shift()
          setMessage(notification ? notification.content : 'Welcome to 10x10 !')
        })
        .catch(err => {
          console.log(err);
        });
    },
    getDeeplinks: ({ setDeeplinks }) => () => {
      ajax.get('/deeplink/house/pools')
        .then(res => setDeeplinks(res.objects))
        .catch(err => {
          console.log(err);
        });
    },
    handlePoolClose: ({ setShowPool }) => () => {
      setShowPool(false)
    },
    handlePoolShow: ({ setShowPool }) => () => {
      setShowPool(true)
    },
    handleSuperbowlClose: ({ setShowSuperbowl }) => () => {
      setShowSuperbowl(false)
    },
    handleSuperbowlShow: ({ setShowSuperbowl }) => () => {
      setShowSuperbowl(true)
    },
    handleVideoClose: ({ setShowVideo }) => () => {
      setShowVideo(false)
    },
    handleVideoShow: ({ setShowVideo, setVideoUrl, setVideoType }) => (url, type = 'video/youtube') => {
      setShowVideo(true)
      setVideoUrl(url)
      setVideoType(type)
    },
    loadingTimeout: ({ setShow }) => (delay = 500) => {
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, delay)
    }
  }),
  lifecycle({
    componentWillMount() {
      const { handleResize, handleScroll, getNotifications, getDeeplinks } = this.props
      window.scrollTo(0, 0);
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
      handleResize();
      getNotifications();
      getDeeplinks();
    },
    componentWillReceiveProps(prevProps) {
      const { loadingTimeout } = this.props
      if (prevProps.location.pathname !== this.props.location.pathname) {
        loadingTimeout()
      }
    },
    componentDidMount() {
      const { loadingTimeout } = this.props
      loadingTimeout()
    },
  }),
  withLayout
)
