import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import './Description.css';

class Description extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array,
    ]),
  }

  render() {
    const { className, children } = this.props
    return (
      <div className={classNames("Description", className)}>{children}</div>
    );
  }
}

export default Description;

