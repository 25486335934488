import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import './Banner.css';

class Banner extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    imgSource: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array,
    ]),
    contentClassName: PropTypes.string,
    subChild: PropTypes.object,
  }

  static contextTypes = {
    screenSize: PropTypes.string,
  }

  render() {
    const { imgSource, children, contentClassName, subChild } = this.props
    return (
      <div className="Banner-container">
        <img src={imgSource} className="Banner-bg" alt="banner" />
        <div className={classNames("Banner-content", contentClassName)}>
          {children}
        </div>
        {subChild}
      </div>
    );
  }
}

export default Banner;
