import React from 'react'
import PropTypes from 'prop-types'

const R = require('ramda')

const PoolPrizeView = ({ deeplink, handleViewChange }) => {
  const { bet_message, prize, play_mode } = deeplink
  const titleMap = {
    each_quarter: ['Q1', 'Q2', 'Q3', 'Q4/Final'],
    half_quarter: ['Half', 'Final'],
  }
  const PrizeUI = R.compose(
    R.ifElse(
      () => R.equals('basic', play_mode),
      prize => <p>{prize}</p>,
      R.compose(
        prizes => <ul className="PoolPrizeView-list">{prizes}</ul>,
        R.zipWith(
          (title, value) => (
            <li key={`prize-${title}-${value}`}>{`${title} : ${value}`}</li>
          ),
          titleMap[play_mode]
        ),
        R.split(';')
      )
    )
  )(prize)
  return (
    <div className="PoolPrizeView-container">
      <div className="PoolPrizeView-box">
        <h4 className="PoolPrizeView-box-title">
          PRIZE
        </h4>
        <div className="PoolPrizeView-box-body">
          {PrizeUI}
        </div>
      </div>
      <div className="PoolPrizeView-box">
        <h4 className="PoolPrizeView-box-title">
          BET MESSAGE
        </h4>
        <div className="PoolPrizeView-box-body">
          <p>{bet_message}</p>
        </div>
      </div>
      <button className="PoolPrizeView-back-button" onClick={handleViewChange('GAME')}>
        <i className="fas fa-arrow-left"></i>
      </button>
    </div>
  )
}

PoolPrizeView.defaultProps = {
};

PoolPrizeView.propTypes = {
  deeplink: PropTypes.object,
  handleViewChange: PropTypes.func,
  screen: PropTypes.object,
};

export default PoolPrizeView;
