import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'

import LinkButton from '../LinkButton/LinkButton'

import './SocialMediaLinks.css';

class SocialMediaLinks extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    return (
      <div className="SocialMedia-container">
        <LinkButton target="_blank" href="https://www.facebook.com/10x10xu" className="SocialMedia-button" type="facebook" />
        <LinkButton target="_blank" href="https://www.instagram.com/10x10app/" className="SocialMedia-button" type="instagram" />
        <LinkButton target="_blank" href="https://www.linkedin.com/company/10444471/" className="SocialMedia-button" type="linkedin" />
        <LinkButton target="_blank" href="https://twitter.com/10x10app" className="SocialMedia-button" type="twitter" />
      </div>
    )
  }
}

export default SocialMediaLinks;
